<template>
  <div class="autocomplete" v-click-outside="outsideClick">
    <CInput
      type="text"
      v-model="search"
      @input="debounceOnChange"
      :isFromLanding="isFromLanding"
      class="formControl"
      :readonly="isReadonly"
      placeholder="Please enter the location "
    />
    <ul v-show="isOpen" v-if="results.length" class="autocomplete-results">
      <li
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
      >
        {{ result["description"] }}
      </li>
    </ul>
    <!-- <p v-else v-show="isOpen">No results found.</p> -->
  </div>
</template>
<script>
import { debounce } from "@/utils/debounce";
import { getLocationData } from "@/api/properties";

export default {
  name: "autocomplete",
  props: ["locationData", "isFromLanding", "isReadonly", "fromFront"],
  watch: {
    locationData(value) {
      this.search = value;
    },
  },
  data() {
    return {
      search: "",
      results: [],
      isOpen: false,
      items: [],
      place_id: null,
    };
  },
  created() {
    if (this.locationData) {
      this.search = this.locationData;
    }
  },
  methods: {
    debounceOnChange: debounce(function () {
      this.handleLocationChange();
    }, 800),
    handleLocationChange() {
      if (!this.isFromLanding) {
        this.$emit("getLocation", this.search);
      }

      if (this.search == "") {
        this.isOpen = false;
      } else {
        getLocationData(this.search, null, this.fromFront).then((response) => {
          setTimeout(() => {}, 10);
          this.results = response.data["predictions"].map(function (item) {
            return item;
          });
          if (!this.results.length) {
            this.$emit("isLocationError", true);
          }
        });
        this.isOpen = true;
      }
    },
    setResult(result) {
      this.search = result["description"];
      this.place_id = result["place_id"];
      this.isOpen = false;
      this.$emit("getLocation", this.search, true, this.place_id);
    },
    outsideClick() {
      this.isOpen = false;
    },
  },
};
</script>
<style scoped>
.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  overflow: auto;
  max-height: 200px;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 9;
  border-radius: 4px;
  padding: 8px 0;
  background: #fff;
}

.autocomplete-result {
  font-size: 14px;
  color: #000000;
  line-height: 17px;
  list-style: none;
  text-align: left;
  padding: 8px 20px;
  cursor: pointer;
}

.autocomplete-result:hover {
  font-family: "Inter-SemiBold";
  background-color: rgba(38, 140, 171, 0.08);
  font-weight: bold;
  color: #28485e;
}
</style>
